import React, { useState, createContext } from "react"

const PriceData = [
  {
    mealText: "CENA ZESTAWU ZA JEDEN DZIEŃ - 5 POSIŁKÓW",
    diets: [
      {
        shortTitle: "Optimal, Wege, niski indeks glikemiczny (IG)",
        prices: [
          {
            option: "1200 kcal",
            days: [58, 57, 56, 55],
          },
          {
            option: "1300 kcal",
            days: [59, 58, 57, 56],
          },
          {
            option: "1400 kcal",
            days: [60, 59, 58, 57],
          },
          {
            option: "1500 kcal",
            days: [61, 60, 59, 58],
          },
          {
            option: "1700 kcal",
            days: [62, 61, 60, 59],
          },
          {
            option: "1800 kcal",
            days: [63, 62, 61, 60],
          },
          {
            option: "2000 kcal",
            days: [65, 64, 63, 62],
          },
          {
            option: "2300 kcal",
            days: [68, 67, 66, 65],
          },
        ],
      },

      {
        shortTitle: "Bez glutenu lub Bez laktozy",
        prices: [
          {
            option: "1200 kcal",
            days: [61, 60, 59, 58],
          },
          {
            option: "1300 kcal",
            days: [62, 61, 60, 59],
          },
          {
            option: "1400 kcal",
            days: [63, 62, 61, 60],
          },
          {
            option: "1500 kcal",
            days: [64, 63, 62, 61],
          },
          {
            option: "1700 kcal",
            days: [65, 64, 63, 62],
          },
          {
            option: "1800 kcal",
            days: [66, 65, 64, 63],
          },
          {
            option: "2000 kcal",
            days: [68, 67, 66, 65],
          },
          {
            option: "2300 kcal",
            days: [71, 70, 69, 68],
          },
          {
            option: "2500 kcal",
            days: [73, 72, 71, 70],
          },
          {
            option: "3000 kcal",
            days: [78, 77, 76, 75],
          },
        ],
      },

      {
        shortTitle: "Bez glutenu i Bez laktozy",
        prices: [
          {
            option: "1200 kcal",
            days: [64, 63, 62, 61],
          },
          {
            option: "1300 kcal",
            days: [65, 64, 63, 62],
          },
          {
            option: "1400 kcal",
            days: [66, 65, 64, 63],
          },
          {
            option: "1500 kcal",
            days: [67, 66, 65, 64],
          },
          {
            option: "1700 kcal",
            days: [68, 67, 66, 65],
          },
          {
            option: "1800 kcal",
            days: [69, 68, 67, 66],
          },
          {
            option: "2000 kcal",
            days: [71, 70, 69, 68],
          },
          {
            option: "2300 kcal",
            days: [74, 73, 72, 71],
          },
          {
            option: "2500 kcal",
            days: [76, 75, 74, 73],
          },
          {
            option: "3000 kcal",
            days: [81, 80, 79, 78],
          },
        ],
      },
      {
        shortTitle: "Sport (5 lub 6 posiłków)",
        prices: [
          {
            option: "2500 kcal",
            days: [70,69,68,67],
          },
          {
            option: "3000 kcal",
            days: [75,74,73,72],
          },
          {
            option: "3500 kcal",
            days: [80,79,78,77],
          },
        ]
      }
    ]
  },
  {
    mealText: "CENA ZESTAWU ZA JEDEN DZIEŃ - 3 POSIŁKI",
    diets: [
      {
        shortTitle: "Optimal, Wege, niski indeks glikemiczny (IG)",
        prices: [
          {
            option: "1200 kcal",
            days: [49,48,47,46],
          },
          {
            option: "1300 kcal",
            days: [50,49,48,47],
          },
          {
            option: "1400 kcal",
            days: [51,50,49,48],
          },
          {
            option: "1500 kcal",
            days: [52,51,50,49],
          },
          {
            option: "1700 kcal",
            days: [53,52,51,50],
          },
          {
            option: "1800 kcal",
            days: [54,53,52,51],
          },
          {
            option: "2000 kcal",
            days: [56,55,54,53],
          },
          {
            option: "2300 kcal",
            days: [59,58,57,56],
          },
        ],
      },



    ]
  },
  // {
  //     mealText: "CENA ZESTAWU ZA JEDEN DZIEŃ - 5 lub 6 posiłków",
  //     diets: [
  //         {
  //             shortTitle: "Sport",
  //             prices: [
  //                 {
  //                   option: "2500 kcal",
  //                   days: [64,63,62,61],
  //                 },
  //                 {
  //                   option: "3000 kcal",
  //                   days: [69,68,67,66],
  //                 },
  //                 {
  //                     option: "3500 kcal",
  //                     days: [74,73,72,71],
  //                   },
  //                 ]
  //         }
  //     ]
  // }



]

export default PriceData
